#builder-form-stabilityai {

  .ant-btn {
    width: 100%;
    font-size: 10px;
  }

  .builder {
    height: 100px;
  }
}