#modal-elements {

  .new-element {
    margin-bottom: 20px;
  }

  .listbt {
    max-height: 70vh;
    overflow: auto;
  }

  .element {
    background: #0068F70D 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    height: 60px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &.exclude {
      background-color: #FF5A5A1A;
    }

    .slider-value {
      display: flex;
      width: 100%;
      bottom: 0;
      padding: 40px;
      align-items: center;
      gap: 10px;

      .slider {
        flex: 1;
        margin: 0;
      }
    }

    .ant-btn.ant-btn-circle {
      min-width: 38px;
      padding-inline-start: 0;
      padding-inline-end: 0;
      border-radius: 50%;
      height: 38px;
      line-height: 0;
      margin-left: 10px;
    }
  }
}

@media (max-width: 576px) {

  .ant-modal-footer {
    bottom: 0;
    position: absolute !important;
    right: 0 !important;
    background: white !important;
    width: 100% !important;
    padding: 20px !important;
  }

  #modal-elements {

    .listbt {
      max-height: calc(100vh - 156px);
      overflow: auto;
      padding-bottom: 80px;
    }

  }
}