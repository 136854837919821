@import "../assets/colors.scss";
@import "../assets/style.scss";

body {
  background-color: #f3f3f3;
}

a {
  text-decoration: none;
  cursor: pointer;
}

#app {

  .block-bts {
    border: 1px solid #dddddd;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    text-align: center;

    button {
      border-radius: 0;
      border: 0;

      &.yc-active {
        font-weight: bold;
        color: white;
        background-color: var(--color-primary);
      }
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }

  .float-left {
    float: left;
  }

  .main {
    overflow-y: hidden;

    >.games {
      position: fixed;
      padding-top: 71px;
      height: 100vh;
    }

    >.content {
      // padding: 100px 0px 0px 0px;
      // background-color: #ffffff;
      // min-height: 100vh;
      // box-shadow: 0px 0px 1px;
      // width: 100%;

      &.full-size {
        padding: 72px 20px 20px 100px;
      }
    }

    .background-home {
      position: absolute;
      width: 50%;
      height: 100%;
      left: -62px;
      overflow: hidden;

      .ant-image {
        width: 100%;

        .ant-image-img {
          width: 100%;
        }
      }
    }
  }

  .app-version {
    position: fixed;
    padding: 0 6px;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    color: #000;
    font-size: 10px;
    z-index: 101;
  }
}

.ant-card {
  background-color: #fafafa;

  .ant-card-head {
    background-color: #1890ff;
    color: white;
  }
}

.input-separate {
  margin-bottom: 10px;
}