.c-field-check {
  display: flex;
  .left {
    background-color: #f6f6f6;
    width: 180px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    border: 1px solid rgb(217, 217, 217);
    border-right: none;
    margin-right: -2px;
    border-radius: 2px;
  }
  .ant-tag {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    padding-right: 24px;
    cursor: pointer;
    .anticon-close {
      background-color: rgba(0,0,0, 0.2);
      position: absolute;
      padding-left: 3px;
      padding-right: 3px;
      top: 0;
      right: 0;
      bottom: 0;
      svg {
        margin-top: 5px;
      }
    }
  }
}