#modal-styles {

  .listbt {
    max-height: 70vh;
    overflow: auto;
  }


  .elements {

    .element-card {
      text-align: center;
      background-color: #0068F71A;
      border-radius: 10px;
      position: relative;
      cursor: pointer;
      border: 1px solid transparent;


      &:hover {
        border: 1px solid #0068F7;

        .name {
          background: #0068F7;
        }
      }

      .image {
        width: 100%;
        border-radius: 10px;
      }

      .name {
        width: 90%;
        height: 28px;
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.4);
        border-radius: 14px;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        bottom: 7px;
        left: 50%;
        transform: translate(-50%, 0%);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #FFFFFF;
          font: normal normal normal 14px/21px Poppins;
        }
      }

      .selected {
        position: absolute;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        bottom: 0;
        background: rgba($color: #0068F7, $alpha: 0.85);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font: normal normal 600 16px/25px Poppins;

        .slider-value {
          position: absolute;
          display: flex;
          width: 100%;
          bottom: 0;
          height: 38px;
          background: rgba($color: #000000, $alpha: 0.2);
          border-radius: 0px 0px 10px 10px;
          padding: 10px;
          align-items: center;
          gap: 10px;

          .slider {
            flex: 1;
            margin: 0;
          }
        }
      }

      .hide {
        display: none;
      }
    }
  }
}

@media (max-width: 576px) {

  #modal-styles {

    .ant-modal-footer {
      bottom: 0;
      position: absolute !important;
      right: 0 !important;
      background: white !important;
      width: 100% !important;
      padding: 20px !important;
    }

    .listbt {
      max-height: calc(100vh - 156px);
      overflow: auto;
      padding-bottom: 80px;
    }

  }
}