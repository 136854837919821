.creative-preview {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 24px;

  .bg {
    width: 100%;
  }

  .ant-image-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

  .card-footer {
    width: 100%;
    justify-content: space-between;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    line-height: 28px;
    padding: 0 4px;

    .content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: black;
    }

    .anticon {
      padding: 0 6px;
      margin-right: -4px;
      margin-left: 8px;
      line-height: 28px;
      background-color: #EEE;

      &:hover {
        background-color: #BBB;
      }
    }
  }

  &:hover {
    border-color: #0068F7;

    .card-footer {
      display: flex;
    }
  }

  .ant-image-mask {
    border-radius: 10px;
  }

  .content-creative {
    border-radius: 10px;
    background: rgba(5, 18, 36, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-image {
      height: 100%;
    }

    .ant-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.component-modal {
  // display: flex;

  .image {
    // max-width: 725px;
    // max-height: 725px;
    max-width: 100%;
    max-height: 100%;
  }

  .more-options {

    .title {
      font: normal normal 600 16px/25px Poppins;
      color: #10294B;
    }

    .content {

      .component-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 20px;

        .content-component {
          width: 50%;

          .component {
            width: auto;
            height: 85px;
            border: 1px solid #B7BEC9;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;

            &:hover {
              background: #717F94 0% 0% no-repeat padding-box;
              cursor: pointer;
            }

            .label {
              font: normal normal medium 13px/20px Poppins;
              letter-spacing: 0px;
              color: #10294B;
              text-align: center;
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 20px;
      }
    }
  }
}

.ant-modal {
  .creative-preview {
    margin-bottom: 0px;
  }
}

@media (max-width: 576px) {
  .more-options {
    padding-top: 20px;
    height: 100%;
    padding-bottom: 80px;
  }
}