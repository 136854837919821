.ant-btn-primary,
.ant-btn-default {
  border-radius: 10px;
  height: 38px;
  width: auto;
}

body {
  transition: all 0.2s ease-in-out;
}

.ant-modal-root .ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal {
  top: 0px;

  .ant-modal-close {
    top: 16px;
    inset-inline-end: 16px;
    width: 38px;
    height: 38px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 60px;
  }

  .ant-modal-close-x {
    line-height: 12px;
  }
}

.ant-modal-content {
  border-radius: 20px !important;
}

.listbt {
  max-height: auto;
  overflow: auto;
}


@media (max-width: 576px) {

  .ant-modal {
    margin: 0 auto;
    padding: 0;
    top: auto;
    width: 100%;
    max-width: 100%;
  }

  .ant-modal-content {
    height: 100%;
    overflow: hidden;
    border-radius: 0px !important;
  }

  .ant-modal-root .ant-modal-wrap {

    align-items: normal;

  }

  .listbt {
    max-height: calc(100vh - 48px);
    overflow: auto;
  }
}