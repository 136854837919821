h1 {
    font-weight: 700;
    color: #37445f;

  }

.c-title {
  color: #37445f;
  font-size: 32px;
  margin-bottom: 24px;
  border-bottom: none;
}

.root-login, .root-sign-up, #root-forgot-password, #active-account {
  display: flex;
  justify-content: center;  

.block-bkg {
  max-width: 800px;
  margin: 200px auto;
  flex:1;

.monster {
  z-index: 100;
  top: 118px;
  width: 230px;
  margin-left: 2%;
  position: fixed;

}

.block-auth {
  z-index : 200;
  max-width: 800px;
  padding: 48px;
  box-shadow: 0 0 20px #37445f1a;
  border-radius: 20px;
  background-color: white;
  position: relative;

    a {
      margin-left: 10px;
    }
  }
}

.bkgcontent {
  z-index: 50;
  position: fixed;

    }
 }


