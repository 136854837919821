#prompt-zone {
  height: calc(100vh - 100px);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prompt {
  max-width: 370px;
  width: 100%;
}

@media (max-width: 1200px) {
  #Prompt-zone {

    .prompt {
      max-width: 260px;
      width: 100%;
    }
  }
}


@media (max-width: 991px) {
  #prompt-zone {
    position: fixed;
    max-width: 100%;
    width: 100%;
    z-index: 10;
    bottom: 78px;
    height: auto;
    transition: all 0.3s ease-in-out;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 0px 12px #0000001c;

    &.hide {
      bottom: -20vh;
    }

    .prompt {
      max-width: 100%;
      width: 100%;
    }

    .copy {
      display: none;
    }
  }
}