.tabs {
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 1px solid #bbbbbb;

  ul {
    margin-bottom: 0;
    clear: both;

    li {
      display: inline-block;
      list-style: none;
      margin-left: 0;

      .anticon {
        vertical-align: text-bottom;
        margin-right: 4px;
      }

      a {
        line-height: 40px;
        display: inline-block;
        padding: 0 20px;
        color: black;
        font-size: 14px;

        &:hover {
          color: var(--color-primary);
        }

        &.active {
          color: var(--color-primary);
          border-bottom: 2px solid var(--color-primary);
        }
      }
    }
  }
}