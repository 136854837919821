#dropdown-menu {
  .ant-btn-compact-first-item {
    width: auto;
    height: 48px;
    background: #0068F70D 0% 0% no-repeat padding-box;
    padding: 0 10px;

    .profil {
      display: flex;
      justify-content: left;
      gap: 5px;
      align-items: center;

      .informations {

        .name,
        .code {
          margin: 0;
        }
      }
    }
  }

  .ant-dropdown-trigger {
    width: 38px;
    height: 48px;
  }
}