#workspace-list {
  z-index: 1;
  width: 100px;
  min-width: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003D;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;


  .workspace {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #0068F766;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      border: 1px solid #0068F7;
    }

    &.active {
      border: 2px solid #0068F7;

    }

    &.add {
      background: #0068F70D 0% 0% no-repeat padding-box;
      border: 1px solid transparent;
    }

    .edit-button {
      position: absolute;
      height: 32px;
      width: 32px;
      top: -10px;
      right: -10px;
      opacity: 0;
    }

    .link {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      .edit-button {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 992px) {
  #workspace-list {
    z-index: 12;
    width: 100%;
    box-shadow: 0px 0px 12px #0000003D;
    padding: 10px;
    display: flex;
    position: fixed;
    bottom: 0;
    flex-direction: initial;

    .workspace {
      width: 60px;
      height: 60px;
      font-size: 12px;
      transition: all 0.2s ease-in-out;
    }
  }
}