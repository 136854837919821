#prompt-input {
  width: 100%;

  .prompt {
    height: 80px;
    width: 100%;
    position: relative;
    margin: 0 auto;

    .input {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      box-shadow: 0px 3px 6px #10294c14;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 120px;
    }

    .button {
      position: absolute;
      font-weight: 500;
      right: 20px;
      top: 16px;
      height: 48px;
      background: transparent linear-gradient(63deg, #9300FF 0%, #FF21B6 47%, #FFA457 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #10294c1f;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .copy {
    position: relative;
    margin-top: 15px;

    .text-area {
      background: #0068F70D 0% 0% no-repeat padding-box;
      border-radius: 10px;
    }

    .button {
      position: absolute;
      top: 0;
      right: 0;
      color: #0068F7;
    }
  }
}