body {

  //############//
  //   COLORS   //
  //############//

  --orange-1: #9300FF;
  --orange-2: #ff0090;

  --grey-1: #354B65;

  --black-1: #151926;

  --white-1: #FFF;
  --white-2: #f0f0f0;

  //###########//
  // Varibales //
  //###########//

  --color-primary: var(--orange-1);
  --color-seconde: var(--orange-2);

  --color-text: var(--grey-1);

  --color-background: #fcfcfc;

  --background-color-light-1: var(--white-1);
  --background-color-light-2: var(--white-2);
}