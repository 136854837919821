body {
  overflow: hidden;

  #root-home {
    background: #FAFCFF !important;
    overflow: hidden;


    .img-home {
      width: auto;
      height: 100vh;
      z-index: 20;
      position: relative;
    }

    .content {
      min-height: 100vh;
      align-content: center;

      .img-htop {
        display: none;
      }

      .content-block {
        padding: 120px;
        margin-top: 150px;
        position: relative;
        z-index: 1;


        .title {
          text-align: left;
          font-size: 48px;
          color: #10294c;
          margin-top: 50px;
          margin-bottom: 40px;
          position: relative;
        }

        .button {
          background: transparent linear-gradient(63deg, #9300FF 0%, #FF21B6 47%, #FFA457 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 20px #0068F733;
          border-radius: 60px;
          padding: 17px 32px;
          border: none;
          font: normal normal medium 22px/33px Poppins;
          color: white;
          border: 1px solid transparent;
          font-weight: 500;

          &:hover {
            background: white;
            color: #db1277;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {

    .content {
      align-content: baseline !important;

      .img-htop {
        display: block !important;

        .img-home {
          width: 120% !important;
          height: auto !important;
          margin-top: -97px !important;
          margin-left: 25px !important;
        }
      }

      .img-hbottom {
        display: none !important;
      }

      .content-block {
        padding: 80px !important;
        margin-top: 0 !important;

        .title {
          text-align: left;
          font-size: 30px !important;
          margin-top: 0 !important;
          margin-bottom: 40px;
          position: relative;
        }
      }
    }
  }


  @media (max-width: 576px) {

    .content {

      .img-htop {

        .img-home {
          width: 130% !important;
          height: auto !important;
          margin-top: -130px !important;
          margin-left: -50px !important;
        }
      }

      .content-block {
        padding: 30px !important;
        margin-top: 40px !important;

        .title {
          text-align: left !important;
          font-size: 30px !important;
          margin-top: 0 !important;
          margin-bottom: 40px;
          position: relative;
        }
      }
    }
  }

}