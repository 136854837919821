#creatives-preview {

  .grid {
    width: 100%;
  }

  .previews {
    max-height: calc(100vh - 100px);
    overflow-y: overlay;
    padding: 24px 0;
    margin: 0 12px;
    padding-bottom: 400px;
  }
}