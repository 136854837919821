#prompt-options {
  width: 100%;

  .options {

    .card-option {
      aspect-ratio: 1 / 1;
      gap: 10px;
      border: 1px solid #0068F766;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease-in-out;
      margin-bottom: 20px;

      &:hover {
        border: 1px solid #0068F7;
      }

      &.selected {
        border: 2px solid #0068F7;
      }

      .title {
        font: normal normal 600 14px/25px Poppins;
        letter-spacing: 0px;
        color: #0068F7;
        margin: 0;
      }

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        filter: grayscale(100%);
      }
    }
  }

  .clear-button {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.prompt-options-modal {
  text-align: center;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    h2 {
      font: normal normal bold 25px/38px Poppins;
      margin: 0;
      color: #10294B;
      margin: 0 8px;
    }
  }

  .description {
    font: normal normal normal 14px/21px Poppins;
    color: #717F94;
  }
}

@media (max-width: 576px) {
  .card-option {

    .title {
      display: none;
    }
  }

  .clear-button {
    margin-top: 0px !important;
    width: 100%;
  }
}