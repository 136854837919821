#header-component {
  left: 0;
  // position: fixed;
  z-index: 100;
  width: 100vw;
  padding: 0;
  justify-content: space-between;
  height: auto;
  color: white;
  background-color: white;
  border-bottom: 1px solid #B7BEC9;

  >.hearder-container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

  }

  .logo {
    height: 80px;
    padding: 10px 0;
  }

  .links {
    #navbarSupportedContent {

      a.b-builder {
        display: inline-block;
        height: 100%;
        padding: 10px;
        color: #10294B;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        border: 20px;
        border-radius: 60px;
        height: 48px;
        min-width: 48px;
        line-height: 44px;
        border: 1px solid #d9d9d9;
        margin: 0 16px 0 0;

        &.active {
          background-color: #10294c;
          color: white;
        }

        &.add {
          font-size: 30px;
          text-align: center;
        }

        &:hover {
          border: 1px solid #0068F766;
        }
      }

      .icon-link {
        vertical-align: text-bottom;
        margin-right: 6px;
      }

      .nav-item {
        a {
          padding-top: 0;
        }

        .edit {
          color: #10294c;
          height: 0px;
          width: 0px;
          min-width: 0;
          margin-left: 0px;
          padding: 0;
          transition: width 0.2s ease;
          transition: all 100ms linear;
          border-width: 0;

          * {
            height: 0px;
            width: 0px;
          }
        }

        &:hover {
          .edit {
            min-width: 32px;
            height: 30px;
            width: 30px;
            margin-left: 10px;
            border-width: 1px;

            * {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 991px) {

    .logo {
      display: block;
      height: 70px;
      padding: 14px 0;
    }

    .navbar-toggler {
      transition: all 0.3s ease-out;
    }

    .nav-item {
      margin-bottom: 20px;
    }

    .mobilenav {
      position: absolute;
      left: 0;
      padding: 20px;
      background: white;
      width: 100%;
      height: 100vh;
      z-index: 1;
      top: 71px;
      transition: all 0.3s ease-out;
    }
  }
}