.c-datepicker {
  > Input {
    width: 260px;
  }
  /*.ant-picker {
    width: 0px;
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
  }*/
}
