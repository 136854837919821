#builder {
  display: flex;
  width: 100%;
  background-color: #FAFCFF;

  .grid {
    width: 100%;
  }
}

@media (max-width: 991px) {
  #builder {
    flex-direction: column-reverse;
  }
}