#modal-variant {

  .simulation {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    border: 1px solid #717F9433;
    border-radius: 10px;
    margin-bottom: 40px;
    display: grid;


    .top,
    .bottom {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      div {
        width: 66px;
        height: 66px;
        background-color: #717F94;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal 600 19px/29px Poppins;
        color: #FFFFFF;

        &.circle {
          border-radius: 50px;
        }

        &.square {
          border-radius: 20px;
        }

        &.diamond {
          border-radius: 20px;
          transform: rotate(45deg);
        }

        &.triangle {}
      }
    }
  }

  .variant-slider {
    max-width: 380px;
    display: flex;
    margin: 0 auto;

    .slider {
      flex: 1;
    }
  }
}