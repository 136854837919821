#publishing {
  position: relative;

  .menu {
    height: 40px;
    padding: 4px;
    position: absolute;
    top: -20px; 
    right: -20px;
    left: -20px;
    box-shadow: 0px 0px 2px #000;
    .icon-app {
      vertical-align: middle;
      margin-right: 20px;
    }
    ul {
      display: inline-block;
      margin-bottom: 0;
      height: 100%;
      margin-left: 10px;
      li {
        a {
          display: inline-block;
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
  }

  .content{
    width: 100%;
    &.content-menu {
      padding-top: 20px;      
    }
  }
}